import makeFAIcon from "utils/makeFAIcon";

import { faLock, faLaptopHouse, faMagic, faAtom, faHandshake, faProjectDiagram, faLaptopCode } from "@fortawesome/free-solid-svg-icons";

export const LaptopIcon = makeFAIcon(faLaptopCode);
export const LockIcon = makeFAIcon(faLock);
export const ProjectIcon = makeFAIcon(faProjectDiagram);
export const LaptopHouseIcon = makeFAIcon(faLaptopHouse);
export const LaptopMagicIcon = makeFAIcon(faMagic);
export const AtomIcon = makeFAIcon(faAtom);
export const HandShakeIcon = makeFAIcon(faHandshake);
